import 'dotenv'
import { Domains, WebDomains, AnalyticsDomain, MicroServices } from './enums'

const env = process.env.REACT_ENV || 'development' // options: 'development', 'test', 'production'.
const cdnDomain = process.env.CDN_DOMAIN || 'https://cdn.pineapple.co.za'
const postHogHostUrl = process.env.POSTHOG_HOST
const postHogAPIKey = process.env.POSTHOG_KEY
const webVersion = '0.0.7'
const currentChatbotCharacter = 'Ntsundeni Mudzunga' // set the global chatbot character

const chatbotProfilePictureUrls = {
  'Ntsundeni Mudzunga': `${cdnDomain}/static/images/ntsundeni.jpg`
}

const chatbotProfilePicture = chatbotProfilePictureUrls[currentChatbotCharacter]

const actionNotPossiblePolicyAlreadyExists = 'policy has already been created'

let domain = Domains[env]
let webDomain = WebDomains[env]
let motorDomain = MicroServices.Motor[env]
let dealershipDomain = MicroServices.Dealership[env]
const recollectionsDomain = MicroServices.Recollections[env]

const Config = {
  authHeader: {
    scheme: process.env.AUTH_SCHEME,
    key: process.env.AUTH_KEY,
    secret: process.env.AUTH_SECRET,
  },
  googleCloud: {
    apiKey: process.env.GOOGLE_CLOUD_API_KEY,
    endpoints: {
      geocode: 'https://maps.googleapis.com/maps/api/geocode/json',
      placesAutocomplete:
        'https://maps.googleapis.com/maps/api/place/autocomplete/json',
      placeDetails: 'https://maps.googleapis.com/maps/api/place/details/json',
    },
  },
  referrerUuidMap: {
    '50c3f366-9ec8-11ec-b909-0242ac120002': 'cars.co.za',
  },
  appsFlyer: {
    devKey: process.env.APPSFLYER_DEV_KEY,
    appId: process.env.APPSFLYER_APP_ID,
    firebaseToken: process.env.FIREBASE_TOKEN,
  },
  splitio: {
    apiKey: process.env.SPLIT_IO_API_KEY,
    premium_strikethrough_split_id: 'split_602e81a595ed5707d4d2a5eb',
    default_treatment_single_discounted_premium:
      'treatment_602e827d95ed5707d4d2a613',
    payment_details_input_emojis_split_id: 'split_633154abb991f169474af0c3',
    default_treatment_input_emojis: 'treatment_633154c4202fdf39abedb0ec',
  },
  services: {
    analytics: {
      attributeTag: `${AnalyticsDomain}/tag/attribute`,
      eventTag: `${AnalyticsDomain}/tag/event`,
      screenTag: `${AnalyticsDomain}/tag/screen`,
      registration: `${AnalyticsDomain}/registrations`,
      appsflyerId: `${AnalyticsDomain}/appsflyer/appsflyerid`,
      googleConversion: `${AnalyticsDomain}/analytics/googleConversion`,
      trackExperiment: `${domain}/ab-tests/experiments`,
    },
    claims : {
      retrieveInsuredAllRiskItems: `${domain}/v2/asset-registers/assets/:user_id`,
      submitAllRiskClaim: `${domain}/v2/claims`,
      submitMotorClaim: `${motorDomain}/claim/submit-fnol/:user_id`
    },
    user: {
      retrieve: `${domain}/v2/users/retrieve`,
      processOptInOptOut: `${domain}/v2/users/opt-in`,
      retrieveV2User: `${domain}/v2/users/v2User`,
      searchByField: `${domain}/v2/users/search-user/field`,
      retrieveSalesAgent: `${domain}/v2/users/retrieve-sales-agent`,
      sendSMS: `${domain}/v2/users/send-sms/:user_id`,
      verifySMS: `${domain}/v2/users/verify-sms-code`,
      sendEmail: `${domain}/v2/users/send-email/:user_id`,
      updateUserOnVerifyAccount: `${domain}/v2/users/update-user-verify-account/:user_id`,
      callLead: `${domain}/v2/users/call-lead`,
      verifyEmail: `${domain}/v2/users/verify-email/:user_id`
    },
    dealership: {
      retrieve: `${dealershipDomain}/dealerships/dealership/:duuid`,
      create: `${dealershipDomain}/dealerships/create`,
      createDealership: `${dealershipDomain}/dealerships/createDealership`,
      update: `${dealershipDomain}/dealerships/update`,
      auth: `${dealershipDomain}/dealerships/auth`,
      sendPasswordResetEmail: `${dealershipDomain}/dealerships/sendPasswordResetEmail`,
      changePassword: `${dealershipDomain}/dealerships/changePassword`,
      createLead: `${dealershipDomain}/dealerships/create-lead`,
      chartData: `${dealershipDomain}/dealerships/dashboard-graphs-data/:id`,
      profile: `${dealershipDomain}/dealerships/profile/:id`,
      bankingDetails: `${dealershipDomain}/dealerships/banking-details/:id`,
      details: `${dealershipDomain}/dealerships/details/:id`,
      key: `${dealershipDomain}/dealerships/key/:id`,
      getLeadStates: `${dealershipDomain}/dealerships/lead-states`,
      getLeads: `${dealershipDomain}/dealerships/leads/:uuid`,
      requestCallback: `${dealershipDomain}/dealerships/request-callback/:user_id`,
      leadEmailNotification: `${dealershipDomain}/dealerships/update-lead-email-notification/:uuid`,
      sendQueryEmail:`${dealershipDomain}/dealerships/send-query-email`,
      sync:`${dealershipDomain}/dealerships/sync`,
    },
    paymentMethods: {
      get: `${domain}/v2/payment-methods/:user_id`,
    },
    vehicle: {
      getAll: `${motorDomain}/vehicles/:user_id`,
      searchMake: `${motorDomain}/vehicles/search/make?q={query}`,
      searchModel: `${motorDomain}/vehicles/search/model?make={make}&q={query}&year={year}`,
      searchTrackerMake: `${motorDomain}/trackers/search/make?q={query}`,
      searchTrackerModel: `${motorDomain}/trackers/search/model?make={make}&q={query}`,
      getByReg: `${motorDomain}/vehicles/reg/:reg_no`,
      getByMmCode: `${motorDomain}/vehicles/:mmcode/:year`,
      create: `${motorDomain}/vehicles`,
      getPremiums: `${motorDomain}/vehicles/premium`,
      getDriveLessPremiums: `${motorDomain}/vehicles/premium/drive-less`,
      getBanks: `${motorDomain}/banks`,
      getBranches: `${motorDomain}/banks/branches`,
      updateVehicleFinanceDetails: `${motorDomain}/vehicles/finance-details/:vehicle_id`,
      getProofOfCover: `${motorDomain}/vehicles/confirmation-of-cover`,
      getConfig: `${domain}/v2/config`,
      searchMMcode: `${motorDomain}/vehicles/search/mmcode?q={query}&year={year}`,
      sendIntercomTags: `${domain}/v2/vehicle/send-intercom-tags`,
      sendIntercomEvent: `${domain}/v2/vehicle/send-intercom-event`,
      updateVin: `${motorDomain}/vehicles/update-vin`,
      deleteVehicle: `${motorDomain}/vehicles/delete`,
    },
    person: {
      get: `${motorDomain}/person/:id`,
      createIWYZEPerson: `${motorDomain}/person`,
      getITCPerson: `${motorDomain}/person/itc/:id`,
      getAfrigisSuburb: `${motorDomain}/suburbs?lat=:lat&lng=:lng`,
      getGoogleAutocomplete: `${motorDomain}/suburbs/autocomplete?address=:address&type=:type`,
      getGooglePlace: `${motorDomain}/suburbs/place?place_id=:placeId`,
      getGooglePlaceLatLng: `${motorDomain}/suburbs/reverse-geocode?lat=:lat&lng=:lng`,
      createClient: `${domain}/iwyze-person/:user_id`,
      updateClient: `${domain}/iwyze-person/:user_id`,
      getAddedPerson: `${motorDomain}/person/iwyze-person/:user_id?type=:type`,
    },
    motorPolicy: {
      create: `${motorDomain}/vehicles/policy`,
      sync: `${motorDomain}/policies/sync/:user_id`,
    },
    conditions: {
      getTermsAndConditions: `${domain}/v2/conditions`,
    },
    tags: {
      create: `${AnalyticsDomain}/analytics/webEvents`,
    },
    interactions: {
      create: `${domain}/v2/interactions/`,
      interact: `${domain}/v2/interactions/interact`,
      update: `${domain}/v2/interactions/update`,
    },
    experiments: {
      create: `${domain}/analytics/experiments/create`,
      update: `${domain}/analytics/experiments/update`,
    },
    wills:{
      processWillLead: `${domain}/wills/processLead`,
    },
    recollections: {
      schedule: `${motorDomain}/v3/lookup/update-case`,
      getCollectionInfo: `${recollectionsDomain}/get/:request_id`,
      saveResponse: `${recollectionsDomain}/response`,
      saveError: `${recollectionsDomain}/error`,
    }
  },
  timeLimit: {
    getExcesses: 90000, // 1 minute 30 seconds
  },
};

const WTF3 = {
  CLIENT: {
    ITC: `${motorDomain}/v2/client/:za_id/:personType/:user_id/itc`,
    UPDATE_DECLARATIONS: `${motorDomain}/v2/client/declarations/:user_id`,
  },
  LOOKUP: {
    EXCESSES: `${motorDomain}/v2/lookup/excesses?user_id=:user_id`,
    ADDRESS_AUTOCOMPLETE: `${motorDomain}/v3/lookup/address/riskscape/autocomplete?q=:query`,
    ADDRESS_AUTOCOMPLETE_FIELDS: `${motorDomain}/v3/lookup/address/riskscape/autocomplete`,
    ADDRESS_LATLNG: `${motorDomain}/v2/lookup/address/riskscape/latlng?lat=:lat&lng=:lng`,
    HIGH_RISK_VEHICLE: `${motorDomain}/v2/lookup/vehicles/high-risk?mmcode=:mmcode&year=:year`,
    QUOTE_TCS: `${motorDomain}/v2/lookup/tcs/quote`,
  },
  QUOTE: {
    UPSERT: `${motorDomain}/v2/quotes/:user_id?commit=:commit&sasria=:sasria`,
    REMOVE_VEHICLE: `${motorDomain}/v2/quotes/:userId/:vehicleId`,
    REMOVE_OBJECT: `${motorDomain}/v2/quotes/:user_id/objects/:object_no`,
    ADD_BANK_DETAILS_TO_QUOTE: `${motorDomain}/v2/quotes/:user_id/bank-details`,
    CREATE_POLICY: `${motorDomain}/v2/quotes/:user_id/purchase`,
  }
}

export { 
  Config,
  env,
  cdnDomain,
  webDomain,
  WTF3,
  currentChatbotCharacter,
  chatbotProfilePicture,
  actionNotPossiblePolicyAlreadyExists,
  postHogHostUrl,
  postHogAPIKey,
  webVersion
}
